import React from 'react';
import { HStack, Card, CardBody, Icon, Tag } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa'

function History({ history }) {
  return (
    <HStack spacing={4}>
      {history.map((entry) => (
        <Card size='sm' key={entry.ts} bgColor='gray.50'>
          <CardBody display='flex' gap={2} alignItems='center'>
            <Tag colorScheme={entry.isRight ? 'green' : 'red'}>{entry.guess.value} {entry.guess.icon}</Tag>
            <Icon as={FaArrowRight} />
            <Tag colorScheme='green'>{entry.take.value} {entry.take.icon}</Tag>
          </CardBody>
        </Card>
      ))}
    </HStack>
  )
}

export default History;
