import React from 'react';
import { Flex, Button } from '@chakra-ui/react'
import { cards, suits } from '../lib/card';

function Guesser({ color, suit, onGuess }) {
  return <Flex bg='gray.50' borderRadius={10} p={6} wrap='wrap' gap={2} alignItems='center' justifyContent='space-around'>
    { cards.map((value, index) => (
      <Button
        colorScheme={color}
        variant='outline'
        size='sm'
        onClick={() => { onGuess({ suit, value, numeric: index + 1, icon: suits[suit] })} }
        w='28%'
        key={value + suit}
      >
        {value} {suits[suit]}
      </Button>
    )) }
  </Flex>;
}

export default Guesser;
