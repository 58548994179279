import React from 'react';
import { Tag } from '@chakra-ui/react'

function Guide({ number, top: isTop, bottom: isBottom, left: isLeft, right: isRight }) {
  const top = isTop ? -30 : (isLeft || isRight) ? 'calc(50% - 10px)' : null
  const bottom = isBottom ? -30 : (isLeft || isRight) ? 'calc(50% - 10px)' : null
  const left = isLeft ? -55 : (isTop || isBottom) ? 'calc(50% - 10px)' : null
  const right = isRight ? -55 : (isTop || isBottom) ? 'calc(50% - 10px)' : null

  return <Tag borderRadius='full' style={{ position: 'absolute', top, bottom, left, right }}>
    {number}
  </Tag>;
}

export default Guide;
