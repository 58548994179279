import { Box, Grid, Image } from '@chakra-ui/react';
import React from 'react';
import PlaymatSide from './PlaymatSide';

function Playmat({ card, guide }) {
  const suit = card.suit
  const side = Math.floor((card.numeric - 1) / 4) < 3
                  ? Math.floor((card.numeric - 1) / 4)
                  : Math.floor(Math.random() * 3)
  const rotation = card.numeric < 13
                     ? ((card.numeric - 1) % 4) * 90
                    : ([-45, 45])[Math.floor(Math.random() * 2)]
  return (
    <Box>
      <Grid
        templateColumns='repeat(3, 1fr)'
        gap={6}
        padding={10}
        bgRepeat='repeat'
        bgImage='url("images/mat.jpg")'
        borderRadius='4%'
      >
        <PlaymatSide guides={[1, 2, 3, 4]} rotation={rotation} visibility={side === 0 || 'hidden'} suit={suit} guide={guide} />
        <PlaymatSide guides={[5, 6, 7, 8]} rotation={rotation} visibility={side === 1 || 'hidden'} suit={suit} guide={guide} />
        <PlaymatSide guides={[9, 10, 'J', 'Q']} rotation={rotation} visibility={side === 2 || 'hidden'} suit={suit} guide={guide} />

        { side === 3 ? <Image
          src={`images/${suit}.png`}
          transform={`rotate(${rotation}deg)`}
        /> : null }
      </Grid>
    </Box>
  );
}

export default Playmat;
