import React from 'react';

import { Flex, Grid, Heading } from '@chakra-ui/react';

import Playmat from './components/Playmat';
import Guesser from './components/Guesser';
import History from './components/History';
import { takeACard } from './lib/card';

function App() {
  const [currentCard, setCurrentCard] = React.useState(takeACard())
  const [history, setHistory] = React.useState([])

  const guess = (guessedCard) => {
    setHistory([
      ...history,
      {
        ts: new Date().getTime(),
        take: { ...currentCard },
        guess: guessedCard,
        isRight: guessedCard.numeric === currentCard.numeric && guessedCard.suit === currentCard.suit
      }
    ])
    setCurrentCard(takeACard())
  }

  return (
    <Flex w='100vw' direction='column' p={6} gap={4}>
      <Playmat card={currentCard} guide={false} />
      <Grid templateColumns='repeat(4, calc(25% - 18px))' gap={6}>
        <Guesser color='black' icon='♣' suit='clubs' onGuess={guess} />
        <Guesser color='red' icon='♥' suit='hearts' onGuess={guess} />
        <Guesser color='black' icon='♠' suit='spades' onGuess={guess} />
        <Guesser color='red' icon='♦' suit='diamonds' onGuess={guess} />
      </Grid>

      <Heading as='h5' size='sm'>
        History
      </Heading>
      <History history={history}/>
    </Flex>
  );
}

export default App;
