import { Box, GridItem, Image } from '@chakra-ui/react';
import React from 'react';
import Guide from './Guide';

function PlaymatSide({ suit, visibility, rotation, guides, guide }) {
  return (
    <GridItem display='flex' justifyContent='center' alignItems="center">
      <Box position='relative'>
        <Image
          maxW={{ sm: '60px', md: '100px', lg: '200px' }}
          maxH={{ sm: '60px', md: '100px', lg: '200px' }}
          src={`images/${suit}.png`}
          visibility={visibility}
          transform={`rotate(${rotation}deg)`}
        />
        { guide && <>
          <Guide number={guides[0]} top />
          <Guide number={guides[1]} right />
          <Guide number={guides[2]} bottom />
          <Guide number={guides[3]} left />
        </> }
      </Box>
    </GridItem>
  );
}

export default PlaymatSide;
